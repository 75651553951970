import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import surveyData from '../data/survey-data'

const initialState = {
  privacyAcknowledgement: false,
  loading: false,
  error: null,
  token: null,
  responses: Object.fromEntries(
    flatten(surveyData.pages.map((page) => page.elements.map((question) => [question.id, null])))
  ),
  progress: Object.fromEntries(
    surveyData.pages.map((page, index) => [
      page.id,
      {
        elements: page.elements.map((question) => question.id),
        answered: 0,
        progress: 0,
        id: index + 1
      }
    ])
  ),
  pagesWithMissingQuestions: [],
  pageCount: surveyData.pages.length,
  hexadTypes: null
}

export const fetchToken = createAsyncThunk(
  'fetchToken',
  async (referral, { dispatch, getState, rejectWithValue }) => {
    const ALLOWED_REFERRALS = ['prolific', 'facebook']

    let referralQuery = ''
    if (referral && ALLOWED_REFERRALS.includes(referral)) {
      referralQuery = '?referral=' + referral
    }
    try {
      const result = await axios.get('/survey/token' + referralQuery)
      return result.data
    } catch (error) {
      console.error(`Received error while fetching token: ${error}`)
      return rejectWithValue(error?.response?.data ? error.response.data : error)
    }
  }
)

export const fetchSession = createAsyncThunk(
  'fetchSession',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState()
      const result = await axios.get(`/survey/${state.token}`)
      return result.data
    } catch (error) {
      console.error(`Received error while fetching session: ${error}`)
      return rejectWithValue(error?.response?.data ? error.response.data : error)
    }
  }
)

export const syncSession = createAsyncThunk(
  'syncSession',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState()
      const result = await axios.put(`/survey/${state.token}`, state.responses)
      return result.data
    } catch (error) {
      console.error(`Received error while syncing session: ${error}`)
      return rejectWithValue(error?.response?.data ? error.response.data : error)
    }
  }
)

export const finalizeSession = createAsyncThunk(
  'finalizeSession',
  async (token, { dispatch, getState, rejectWithValue }) => {
    try {
      const result = await axios.get(`/survey/${token}/finalize`)
      return result.data
    } catch (error) {
      console.error(`Received error while finalizing session: ${error}`)
      return rejectWithValue(error?.response?.data ? error.response.data : error)
    }
  }
)

const slice = createSlice({
  name: 'surveySession',
  initialState,
  reducers: {
    toggledPrivacyAcknowledgement(state, action) {
      state.privacyAcknowledgement = !state.privacyAcknowledgement
    },
    resetError(state, action) {
      state.error = null
    },
    foundToken(state, action) {
      state.token = action.payload
    },
    gotQuestionResponse(state, action) {
      state.responses[action.payload.id] = action.payload.value
    },
    updatedProgress(state, action) {
      state.progress = action.payload
    },
    updatedPagesWithMissingQuestions(state, action) {
      state.pagesWithMissingQuestions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch token
      .addCase(fetchToken.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchToken.fulfilled, (state, action) => {
        state.loading = false
        state.token = action.payload.token
      })
      .addCase(fetchToken.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.title ? action.payload.title : action.payload
      })
      // Fetch session
      .addCase(fetchSession.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchSession.fulfilled, (state, action) => {
        state.loading = false
        if (!isEmpty(action.payload)) state.responses = action.payload
      })
      .addCase(fetchSession.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.title ? action.payload.title : action.payload
      })
      // Sync session
      .addCase(syncSession.pending, (state, action) => {})
      .addCase(syncSession.fulfilled, (state, action) => {})
      .addCase(syncSession.rejected, (state, action) => {
        state.error = action.payload?.title ? action.payload.title : action.payload
      })
      // Finalize session
      .addCase(finalizeSession.pending, (state, action) => {
        state.loading = true
      })
      .addCase(finalizeSession.fulfilled, (state, action) => {
        state.loading = false
        state.hexadTypes = action.payload
      })
      .addCase(finalizeSession.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.title ? action.payload.title : action.payload
      })
  }
})

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const {
  foundToken,
  resetError,
  toggledPrivacyAcknowledgement,
  gotQuestionResponse,
  updatedProgress,
  updatedPagesWithMissingQuestions
} = slice.actions

// Export the slice reducer as the default export
export default slice.reducer
