import React, { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import '../css/imprint.css'
import imprint_de from '../lang/de/imprint.md'
import imprint_en from '../lang/en/imprint.md'
import Markdown from 'react-markdown'

export default function Imprint(props) {
  const { i18n } = useTranslation('imprint')
  const [markdown, setMarkdown] = useState('')

  useEffect(() => {
    fetch(i18n.language.substring(0, 2) === 'de' ? imprint_de : imprint_en)
      .then((res) => res.text())
      .then((text) => setMarkdown(text))
  }, [setMarkdown, i18n.language])

  return (
    <div className="imprint">
      <div className="content">
        <Markdown>{markdown}</Markdown>
      </div>
    </div>
  )
}
