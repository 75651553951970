import React from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import Survey from './Survey'
import Home from './Home'
import Result from './Result'
import NotFound from './NotFound'
import Imprint from './Imprint'
import Privacy from './Privacy'
import '../css/style.css'
import { ReactComponent as Globe } from '../assets/globe.svg'
import AboutHexad from './AboutHexad'
import AlertModal from '../components/AlertModal'
import ScrollToTop from '../components/ScrollToTop'

export default () => {
  const { t, i18n } = useTranslation('general')
  const error = useSelector((state) => state.error)

  return (
    <>
      {error && <AlertModal type="NO_DATABASE_CONNECTION" />}
      <header className="header">
        <Link to="/">
          <div className="header-inner">
            <div className="header-image">
              <img className="image-inner" src="../logo.svg" alt="" />
            </div>
            <h1>{t('header_title', 'Discover your HEXAD type')}</h1>
          </div>
        </Link>
        <Link className="about-hexad-link" to="/about-hexad">
          {t('header_about_link', 'About Hexad')}
        </Link>
        <div className="language-switcher">
          <div className="dropdown">
            <Globe />{' '}
            <span className="currentLanguage">
              {i18n.language.substring(0, 2) === 'en' ? 'English' : 'Deutsch'}
            </span>
          </div>
          <div className="dropdown-content">
            <div
              onClick={(e) => {
                e.preventDefault()
                i18n.changeLanguage('en-US')
              }}
            >
              English
            </div>
            <div
              onClick={(e) => {
                e.preventDefault()
                i18n.changeLanguage('de-DE')
              }}
            >
              Deutsch
            </div>
          </div>
        </div>
      </header>
      <ScrollToTop />
      <Routes>
        <Route path="/quiz/*" element={<Survey />} />
        <Route path="/result" element={<Result />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/about-hexad" element={<AboutHexad />} />
        <Route exact path="/" element={<Home />} />
        <Route element={<NotFound />} />
      </Routes>
      <footer className="footer">
        <div className="footer-inner">
          <div className="footer-text">
            <Trans i18nKey="footer_disclaimer" t={t}>
              MyHexad is part of a
              <Link to="/Imprint#about" className="link">
                research project
              </Link>
              on HEXAD user types and their game element preferences
            </Trans>
          </div>
          <div className="footer-links">
            <Link className="footer-link" to="/privacy">
              {t('footer_privacy_link', 'Privacy')}
            </Link>
            <Link className="footer-link" to="/imprint">
              {t('footer_imprint_link', 'Imprint')}
            </Link>
          </div>
        </div>
      </footer>
    </>
  )
}
