import React from 'react'
import { useTranslation } from 'react-i18next'

import '../../css/questiontypes/likert-7.css'
import { useSelector } from 'react-redux'
import Markdown from 'react-markdown'
import isEmpty from 'lodash/isEmpty'

export default function Likert7({ handleChange: handleSurveyChange, question, options }) {
  const { t, i18n } = useTranslation('general')
  const questionState = useSelector((state) => state.responses[question.id])

  const handleChange = (event) => {
    let questionId = event.target.getAttribute('data-question-id')
    let value = event.target.getAttribute('data-value')
    handleSurveyChange(questionId, value)
  }

  const labelDisagree = () => {
    if (options.hasOwnProperty('labelDisagree')) {
      return options.labelDisagree[i18n.language.substring(0, 2)]
    }
    return t('general:question_label_disagree', 'Strongly disagree')
  }

  const labelAgree = () => {
    if (options.hasOwnProperty('labelAgree')) {
      return options.labelAgree[i18n.language.substring(0, 2)]
    }
    return t('general:question_label_agree', 'Strongly agree')
  }

  const SubTitle = () => {
    if (question.hasOwnProperty('subtitle') && !isEmpty(question.subtitle)) {
      return (
        <div className="subtitle">
          <Markdown>{question.subtitle[i18n.language.substring(0, 2)]}</Markdown>
        </div>
      )
    }
  }

  return (
    <div className="likert7Question">
      <div className="title">{question.title[i18n.language.substring(0, 2)]}</div>
      <SubTitle />
      <div className="options">
        <div className="label disagree">{labelDisagree()}</div>
        <div className="items">
          <div
            data-question-id={question.id}
            data-value="1"
            className={`item disagree max ${questionState === '1' ? 'active' : ''}`}
            onClick={handleChange}
          >
            <i className="im im-check-mark"></i>
          </div>
          <div
            data-question-id={question.id}
            data-value="2"
            className={`item disagree med ${questionState === '2' ? 'active' : ''}`}
            onClick={handleChange}
          >
            <i className="im im-check-mark"></i>
          </div>
          <div
            data-question-id={question.id}
            data-value="3"
            className={`item disagree min ${questionState === '3' ? 'active' : ''}`}
            onClick={handleChange}
          >
            <i className="im im-check-mark"></i>
          </div>
          <div
            data-question-id={question.id}
            data-value="4"
            className={`item neutral ${questionState === '4' ? 'active' : ''}`}
            onClick={handleChange}
          >
            <i className="im im-check-mark"></i>
          </div>
          <div
            data-question-id={question.id}
            data-value="5"
            className={`item agree min ${questionState === '5' ? 'active' : ''}`}
            onClick={handleChange}
          >
            <i className="im im-check-mark"></i>
          </div>
          <div
            data-question-id={question.id}
            data-value="6"
            className={`item agree med ${questionState === '6' ? 'active' : ''}`}
            onClick={handleChange}
          >
            <i className="im im-check-mark"></i>
          </div>
          <div
            data-question-id={question.id}
            data-value="7"
            className={`item agree max ${questionState === '7' ? 'active' : ''}`}
            onClick={handleChange}
          >
            <i className="im im-check-mark"></i>
          </div>
        </div>
        <div className="label agree">{labelAgree()}</div>
      </div>
      <div className="label-mobile">
        <div className="label disagree">{labelDisagree()}</div>
        <div className="label agree">{labelAgree()}</div>
      </div>
    </div>
  )
}
