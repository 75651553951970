const SESSION_STORAGE_TOKEN = 'survey_token'
const SESSION_STORAGE_REFERRAL = 'survey_referral'

export const loadToken = () => {
  try {
    const token = sessionStorage.getItem(SESSION_STORAGE_TOKEN)
    const tokenRegex = RegExp(
      '^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$',
      'i'
    )
    if (tokenRegex.test(token)) {
      return token
    }
    if (token !== null) {
      console.log('Found token, but validation failed!')
    }
    return undefined
  } catch (err) {
    return undefined
  }
}

export const saveToken = (token) => {
  try {
    sessionStorage.setItem(SESSION_STORAGE_TOKEN, token)
  } catch (err) {
    console.log(err)
  }
}

export const saveReferral = (refferal) => {
  try {
    switch (refferal) {
      case 'prolific':
        sessionStorage.setItem(SESSION_STORAGE_REFERRAL, 'prolific')
        break
      case 'facebook':
        sessionStorage.setItem(SESSION_STORAGE_REFERRAL, 'facebook')
        break
      default:
        sessionStorage.setItem(SESSION_STORAGE_REFERRAL, 'none')
        break
    }
  } catch (err) {
    console.log(err)
  }
}

export const loadReferral = () => {
  try {
    const referral = sessionStorage.getItem(SESSION_STORAGE_REFERRAL)
    if (referral === null) return null
    return referral
  } catch (err) {
    console.log(err)
    return null
  }
}
