import grin from '../../assets/hexadguy/Grin.svg'

export default {
  id: 'demographics',
  description: {
    en: 'All done, perfect! Just some quick questions about yourself, which are entirely voluntary, and then you can see your personal HEXAD type on the final page!',
    de: 'Alles fertig, perfekt! Nur noch ein paar kurze Fragen zu deiner Person, welche vollkommen freiwillig sind, und dann kannst du auf der letzten Seite deinen persönlichen HEXAD-Typ sehen!'
  },
  image: grin,
  layout: 'single-column',
  elements: [
    {
      id: 'DA',
      type: { name: 'number-input', options: {} },
      optional: true,
      title: { en: 'How old are you?', de: 'Wie alt bist du?' },
      subtitle: {}
    },
    {
      id: 'DG',
      type: { name: 'radio-buttons', options: { horizontal: true } },
      optional: true,
      title: { en: 'What is your gender?', de: 'Was ist dein Geschlecht?' },
      subtitle: {},
      choices: [
        {
          value: 'female',
          label: { en: 'Female', de: 'Weiblich' }
        },
        {
          value: 'male',
          label: { en: 'Male', de: 'Männlich' }
        },
        {
          value: 'other',
          label: { en: 'Other', de: 'Divers' }
        }
      ]
    },
    {
      id: 'DO',
      type: { name: 'drop-down', options: { preset: 'countries' } },
      optional: true,
      title: { en: 'Where are you from?', de: 'Woher kommst du?' },
      subtitle: {}
    },
    {
      id: 'DH',
      type: { name: 'number-input', options: {} },
      optional: true,
      title: { en: 'How tall are you? (in cm)', de: 'Wie groß bist du? (in cm)' },
      subtitle: {}
    },
    {
      id: 'DW',
      type: { name: 'number-input', options: {} },
      optional: true,
      title: { en: 'What is your body weight? (in kg)', de: 'Was ist dein Körpergewicht? (in kg)' },
      subtitle: {}
    }
  ]
}
