import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toggledPrivacyAcknowledgement, fetchToken, foundToken } from '../redux/slice'
import { useNavigate, useSearchParams } from 'react-router-dom'
import '../css/home.css'
import { hello } from '../data/survey-data'
import { Helmet } from 'react-helmet'
import { loadToken, saveReferral } from '../util/sessionStorage'
import Markdown from 'react-markdown'
import home_de from '../lang/de/home.md'
import home_en from '../lang/en/home.md'

export default () => {
  const { t, i18n } = useTranslation('home')
  const [tip, setTip] = useState(false)
  const privacyAcknowledgement = useSelector((state) => state.privacyAcknowledgement)
  const loading = useSelector((state) => state.loading)
  const token = useSelector((state) => state.token)
  const error = useSelector((state) => state.error)
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [markdown, setMarkdown] = useState('')

  useEffect(() => {
    fetch(i18n.language.substring(0, 2) === 'de' ? home_de : home_en)
      .then((res) => res.text())
      .then((text) => setMarkdown(text))
  }, [setMarkdown, i18n.language])

  useEffect(() => {
    if (searchParams && searchParams.has('referral')) {
      console.log('Found referral: ' + searchParams.get('referral'))
      saveReferral(searchParams.get('referral'))
    }
  }, [searchParams])

  const handleChange = () => {
    dispatch(toggledPrivacyAcknowledgement())
    setTip(false)
  }

  const handleSubmit = (event) => {
    const handleToken = async () => {
      // Check for token in state
      if (!token) {
        // Check for token in session storage
        const sessionStorageToken = loadToken()
        if (sessionStorageToken) {
          dispatch(foundToken(sessionStorageToken))
        } else {
          await dispatch(
            fetchToken(
              searchParams && searchParams.has('referral') ? searchParams.get('referral') : null
            )
          )
        }
      }
      if (!error) navigate('/quiz')
    }

    if (!privacyAcknowledgement) {
      event.preventDefault()
      setTip(true)
    } else {
      handleToken()
    }
  }

  const Checkbox = () => {
    return (
      <div className="privacy-checkbox">
        <div
          className={`privacy-checkbox-inner ${privacyAcknowledgement ? 'active' : ''} ${
            tip && !privacyAcknowledgement ? 'tip' : ''
          }`}
          onClick={handleChange}
        >
          <i className="im im-check-mark"></i>
        </div>
      </div>
    )
  }

  const StartSurveyButton = () => {
    return (
      <button
        type="button"
        className={`startbutton ${!privacyAcknowledgement ? 'disabled' : ''} ${
          loading ? 'loading' : ''
        }`}
        onClick={handleSubmit}
        key="start-button"
      >
        <span>{t('continue_button', 'Discover your HEXAD type!')}</span>
      </button>
    )
  }

  return (
    <>
      <Helmet>
        <title>Discover your HEXAD gamification user type!</title>
        <link rel="manifest" href="https://myhexad.com/manifest.json" />
        <meta
          name="description"
          content="The HEXAD gamification typology from Andrzej Marczewski classifies people into different gamification user types. Find out your own HEXAD gamification type in less than 10 minutes!"
        />
        <meta
          name="keywords"
          content="my hexad, myhexad, hexad types, hexad gamification, gamification, gamification typology, user typology, user types, gamification users"
        />
      </Helmet>
      <div className="home">
        <div className="content">
          <div className="right">
            <div className="titleguy">
              <img className="titleguy-image" src={hello} alt=""></img>
            </div>
          </div>
          <div className="left">
            <Markdown>{markdown}</Markdown>
            <div className="acknowledge-privacy-statement">
              <Checkbox />
              <p className={`${tip && !privacyAcknowledgement ? 'tip' : ''}`}>
                <Trans i18nKey="privacy_consent_text" t={t}>
                  I agree to the processing and storage of my answers for research purposes
                  according to the
                  <a href="/privacy" className="link">
                    privacy statement
                  </a>
                  .
                </Trans>
              </p>
            </div>
            <StartSurveyButton />
          </div>
        </div>
      </div>
    </>
  )
}
