import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup
} from 'pure-react-carousel'
import ContentLoader from 'react-content-loader'
import { loadToken, loadReferral } from '../util/sessionStorage'
import AlertModal from '../components/AlertModal'
import 'pure-react-carousel/dist/react-carousel.es.css'
import '../css/result.css'
import {
  freeSpirit,
  philanthropist,
  socializer,
  achiever,
  player,
  disruptor
} from '../data/survey-data'
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share'
import { finalizeSession, foundToken, fetchSession } from '../redux/slice'

export default ({}) => {
  const [typeRanking, setTypeRanking] = useState()
  const [equalRanking, setEqualRanking] = useState([])
  const [ready, setReady] = useState(false)
  // locked is implemented so the modal is not shown when a network problem occured
  const [noData, setNoData] = useState({ status: false, locked: false })
  const [referral, setReferral] = useState()
  const { t } = useTranslation('result')
  const token = useSelector((state) => state.token)
  const error = useSelector((state) => state.error)
  const hexadTypes = useSelector((state) => state.hexadTypes)
  const propsProgress = useSelector((state) => state.progress)
  const dispatch = useDispatch()

  const progress = {
    philanthropist: {
      name: 'Philanthropist',
      shortDescription:
        'Philanthropists are motivated by purpose, knowledge-transfer and the support of others.',
      image: philanthropist
    },
    socializer: {
      name: 'Socializer',
      shortDescription:
        'Socialisers are motivated by relatedness, social connections and interaction with others.',
      image: socializer
    },
    freeSpirit: {
      name: 'Free Spirit',
      shortDescription:
        'Free Spirits are motivated by autonomy, self-expression and freedom to explore.',
      image: freeSpirit
    },
    achiever: {
      name: 'Achiever',
      shortDescription: 'Achievers are motivated by mastery, self-improvement and challenges.',
      image: achiever
    },
    player: {
      name: 'Player',
      shortDescription:
        'Players are motivated rewards, collections and return for their investment.',
      image: player
    },
    disruptor: {
      name: 'Disruptor',
      shortDescription:
        'Disruptors are motivated by change, participation and opportunities to improve the system.',
      image: disruptor
    }
  }

  const calcMatchingType = () => {
    const perc = hexadTypes.percentages
    let types = Object.keys(perc)
    types.sort((a, b) => (perc[a] > perc[b] ? -1 : 1))
    setTypeRanking(types)
    calcEqualRanking(types)
  }

  const calcEqualRanking = (types) => {
    let array = []
    if (types && hexadTypes) {
      setEqualRanking(array.push(types[0]))
      for (
        let i = 0;
        hexadTypes.percentages[types[i]] === hexadTypes.percentages[types[i + 1]];
        i++
      ) {
        setEqualRanking(array.push(types[i + 1]))
      }
    }
    setEqualRanking(array)
    if (array.length > 0 && progress) {
      setReady(true)
    }
  }

  useEffect(() => {
    if (token) {
      setReady(false)
      const run = async () => {
        await dispatch(finalizeSession(token))
      }
      run()
    } else {
      setReady(false)
    }
  }, [token])

  useEffect(() => {
    if (token && propsProgress) {
      setNoData((prevState) => ({ ...prevState, status: false }))
    } else {
      setNoData((prevState) => ({ ...prevState, status: true }))
    }
  }, [token, propsProgress])

  useEffect(() => {
    if (hexadTypes) calcMatchingType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hexadTypes])

  useEffect(() => {
    if (error) {
      setNoData((prevState) => ({ ...prevState, locked: true }))
    }
  }, [error])

  // Get token from session storage by reload
  useEffect(() => {
    const stored_token = loadToken()
    // Check if we already stored some data:
    if (stored_token && stored_token !== 'null') {
      console.log('Found token')
      const run = async () => {
        dispatch(foundToken(stored_token))
        await dispatch(fetchSession(stored_token))
      }
      run()
    }
  }, [])

  // Get referral from session storage
  useEffect(() => {
    const referral = loadReferral()
    if (referral) {
      setReferral(referral)
    }
  }, [])

  return (
    <div className="result">
      {noData.status && !noData.locked && <AlertModal type="RESULT_BUT_NOT_FINISHED" />}
      <div className="result-type">
        {ready ? (
          <MainTypes
            typeRanking={typeRanking}
            t={t}
            progress={progress}
            hexadTypes={hexadTypes}
            equalRanking={equalRanking}
          />
        ) : (
          <NoType />
        )}
      </div>
      {referral === 'prolific' && (
        <a
          href="https://app.prolific.co/submissions/complete?cc=38785F28"
          target="_blank"
          rel="noreferrer"
          className="prolific-redirect"
        >
          <div className="button-content">
            <div className="button-prolific">
              <div className="prolific-text">{t('prolific_button', 'Back to Prolific')}</div>
              <div className="arrow">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                </svg>
              </div>
            </div>
          </div>
        </a>
      )}
      <div className="other-results">
        <div className="other-results-content">
          <div className="h1">
            {t('other_types_heading', 'See how much you correspond to the other HEXAD types:')}
          </div>
          <div className="p">{t('other_types_description')}</div>
          <div className="result-bars-content">
            {typeRanking?.map((id) => {
              if (ready) {
                return (
                  <ResultBar
                    progress={parseFloat(hexadTypes.percentages[id]).toFixed(0)}
                    id={id}
                    key={id}
                    t={t}
                  />
                )
              } else {
                return <NoResultBar key={id} />
              }
            })}
          </div>
          <div className="button-content">
            <div className="button-about">
              <Link to="/about-hexad" className="button-link">
                {t('about_hexad_button', 'More information about the HEXAD types')}
              </Link>
            </div>
          </div>
          <div className="thankyou-content">
            <div className="p">
              <Trans i18nKey="thankyou_description" t={t}>
                Thank you for participating in the HEXAD survey. Your results are very valuable for
                our research. For further information about our research project and its theoretical
                foundation, please klick
                <Link to="/imprint/#about" className="link">
                  here
                </Link>
                .
              </Trans>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ResultBar = (props) => {
  const style = {
    clipPath: 'inset(0 0 0 ' + props.progress + '%)',
    WebkitClipPath: 'inset(0 0 0 ' + props.progress + '%)'
  }

  return (
    <div className="hexad-type">
      <div className="title">{props.t(props.id + '.name')}</div>
      <div className="description">{props.t(props.id + '.shortDescription')}</div>
      <div className="progress">
        <div className={`back ${props.id}`}>
          <span className="label">{props.progress}%</span>
        </div>
        <div className="front" style={style}>
          <span className="label">{props.progress}%</span>
        </div>
      </div>
    </div>
  )
}

const NoResultBar = (props) => {
  return (
    <div className="hexad-type">
      <ContentLoader
        speed={2}
        className="contentLoading"
        viewBox="0 0 200 26"
        backgroundColor="#ececec"
        foregroundColor="#cecece"
        {...props}
      >
        <rect x="0" y="0" rx="0" ry="0" width="60" height="6" />
        <rect x="0" y="10" rx="0" ry="0" width="150" height="4" />
        <rect x="0" y="18" rx="0" ry="0" width="200" height="8" />
      </ContentLoader>
    </div>
  )
}

const Headers = (props) => {
  let String = props.t('title_start')
  for (let i = 0; i < props.equalRanking.length - 1; i++) {
    if (props.equalRanking[i].startsWith('a')) {
      String += props.t('title_vocale')
    } else {
      String += props.t('title_consonant')
    }
    String += props.t(props.equalRanking[i] + '.name')
    if (i < props.equalRanking.length - 2) {
      String += ', '
    }
  }
  String += props.t('title_and')
  if (props.equalRanking[props.equalRanking.length - 1].startsWith('a')) {
    String += props.t('title_vocale')
  } else {
    String += props.t('title_consonant')
  }
  String += props.t(props.equalRanking[props.equalRanking.length - 1] + '.name') + '!'

  return (
    <div className="content-type-title">
      <div className="title-multiple">{String}</div>
    </div>
  )
}

const MainTypes = (props) => {
  if (props.typeRanking && props.hexadTypes) {
    if (props.equalRanking.length > 1) {
      return (
        <>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={40}
            totalSlides={props.equalRanking.length}
            isIntrinsicHeight={true}
          >
            <div className="slider-wrapper">
              <Headers equalRanking={props.equalRanking} t={props.t} />
              <Slider className="slider">
                {props.equalRanking.map((value, index) => {
                  return (
                    <Slide key={value + index} index={index}>
                      <MainType
                        type={props.equalRanking[index]}
                        t={props.t}
                        progress={props.progress}
                        hexadTypes={props.hexadTypes}
                        showHeader={false}
                      />
                    </Slide>
                  )
                })}
              </Slider>
              <ButtonBack className="slider-back">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                </svg>
              </ButtonBack>
              <ButtonNext className="slider-next">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                </svg>
              </ButtonNext>
              <DotGroup className="slider-dots" />
            </div>
          </CarouselProvider>
        </>
      )
    } else if (props.equalRanking.length === 1) {
      return (
        <div className="slider-wrapper">
          <MainType
            type={props.equalRanking[0]}
            t={props.t}
            progress={props.progress}
            hexadTypes={props.hexadTypes}
            showHeader={true}
          />
        </div>
      )
    }
  }

  return null
}

const MainType = (props) => {
  return (
    <div className="content-type">
      <div className="right">
        <div className="hexadguy">
          <img className="hexadguy-image" src={props.progress[props.type].image} alt=""></img>
        </div>
      </div>
      <div className="left">
        {props.showHeader && <div className="title">{props.t(props.type + '.heading')}</div>}
        <div className="text">
          <Trans i18nKey={props.type + '.longDescription'} t={props.t}></Trans>
        </div>
        {/*<div className="share">
          <div className="share-text">
            {props.t('share_result', 'Share your result with your friends:')}
          </div>
          <div className="share-buttons">
            <FacebookShareButton
              url="https://myhexad.com"
              quote={props.t(props.type + '.social_media_share_text')}
              hashtag="#myhexad"
            >
              <FacebookIcon size={32} />
            </FacebookShareButton>
            <TwitterShareButton
              url="https://myhexad.com/"
              title={props.t(props.type + '.social_media_share_text')}
              hashtags={['#myhexad']}
            >
              <TwitterIcon size={32} />
            </TwitterShareButton>
          </div>
        </div>*/}
      </div>
    </div>
  )
}

const NoType = (props) => {
  return (
    <div className="slider-wrapper type-placeholder">
      <div className="content-type">
        <div className="right">
          <ContentLoader
            speed={2}
            className="contentLoading"
            viewBox="0 0 170 130"
            backgroundColor="#ececec"
            foregroundColor="#cecece"
            {...props}
          >
            <rect x="25" y="20" rx="0" ry="0" width="120" height="100" />
          </ContentLoader>
        </div>
        <div className="left">
          <div className="desktop-loading-placeholder">
            <ContentLoader
              speed={2}
              className="contentLoading"
              viewBox="0 0 300 130"
              backgroundColor="#ececec"
              foregroundColor="#cecece"
              {...props}
            >
              <rect x="0" y="0" rx="0" ry="0" width="150" height="10" />
              <rect x="0" y="20" rx="0" ry="0" width="260" height="6" />
              <rect x="0" y="28" rx="0" ry="0" width="224" height="6" />
              <rect x="0" y="36" rx="0" ry="0" width="215" height="6" />
              <rect x="0" y="44" rx="0" ry="0" width="238" height="6" />

              <rect x="0" y="60" rx="0" ry="0" width="210" height="6" />
              <rect x="0" y="68" rx="0" ry="0" width="220" height="6" />
              <rect x="0" y="76" rx="0" ry="0" width="215" height="6" />
              <rect x="0" y="84" rx="0" ry="0" width="230" height="6" />
              <rect x="0" y="92" rx="0" ry="0" width="200" height="6" />
              <rect x="0" y="100" rx="0" ry="0" width="210" height="6" />
              <rect x="0" y="118" rx="0" ry="0" width="160" height="7" />
              <rect x="170" y="114" rx="0" ry="0" width="15" height="15" />
              <rect x="190" y="114" rx="0" ry="0" width="15" height="15" />
            </ContentLoader>
          </div>
          <div className="mobile-loading-placeholder">
            <ContentLoader
              speed={2}
              className="contentLoading"
              viewBox="0 0 300 163"
              backgroundColor="#ececec"
              foregroundColor="#cecece"
              {...props}
            >
              <rect x="75" y="0" rx="0" ry="0" width="150" height="12" />
              <rect x="20" y="20" rx="0" ry="0" width="260" height="8" />
              <rect x="37.5" y="31" rx="0" ry="0" width="225" height="8" />
              <rect x="42.5" y="42" rx="0" ry="0" width="215" height="8" />
              <rect x="31" y="53" rx="0" ry="0" width="238" height="8" />

              <rect x="45" y="75" rx="0" ry="0" width="210" height="8" />
              <rect x="40" y="86" rx="0" ry="0" width="220" height="8" />
              <rect x="42.5" y="97" rx="0" ry="0" width="215" height="8" />
              <rect x="35" y="108" rx="0" ry="0" width="230" height="8" />
              <rect x="50" y="119" rx="0" ry="0" width="200" height="8" />
              <rect x="45" y="130" rx="0" ry="0" width="210" height="8" />
              <rect x="47.5" y="150" rx="0" ry="0" width="160" height="9" />
              <rect x="217.5" y="147" rx="0" ry="0" width="15" height="15" />
              <rect x="237.5" y="147" rx="0" ry="0" width="15" height="15" />
            </ContentLoader>
          </div>
        </div>
      </div>
    </div>
  )
}
