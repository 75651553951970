import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import surveyData from '../data/survey-data'
import NotFound from './NotFound'
import SurveyDescription from '../components/SurveyDescription'
import ProgressBar from '../components/ProgressBar'
import Pagination from '../components/Pagination'
import AlertModal from '../components/AlertModal'
import QuestionWrapper from '../components/QuestionWrapper'
import Validation from '../components/Validation'

export default ({}) => {
  const [consentModal, setConsentModal] = useState(false)
  const privacyAcknowledgement = useSelector((state) => state.privacyAcknowledgement)
  const token = useSelector((state) => state.token)

  useEffect(() => {
    if (privacyAcknowledgement || token) {
      setConsentModal(false)
    } else {
      setConsentModal(true)
    }
  }, [privacyAcknowledgement, token])

  const Modal = () => {
    if (consentModal) {
      return <AlertModal type="NO_CONSENT" />
    }
    return null
  }

  return (
    <>
      <Modal />
      <Routes>
        <Route
          path={':pageId'}
          element={
            <>
              <SurveyDescription data={surveyData} />
              <ProgressBar data={surveyData} />
              <QuestionWrapper data={surveyData} />
              <Validation />
              <Pagination />
            </>
          }
        />
        <Route path="/" element={<Navigate to={`1`} />} />
        <Route element={<NotFound />} />
      </Routes>
    </>
  )
}
