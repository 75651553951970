import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import isString from 'lodash/isString'

import '../css/alert-modal.css'
import { useDispatch, useSelector } from 'react-redux'
import { resetError } from '../redux/slice'

export default ({ type }) => {
  const { t } = useTranslation('general')
  const error = useSelector((state) => state.error)
  const dispatch = useDispatch()

  const closePopup = (event) => {
    event.preventDefault()
    dispatch(resetError())
  }

  switch (type) {
    case 'NO_DATABASE_CONNECTION':
      return (
        <div className="alert-modal" role="dialog">
          <div className="modal-content">
            <svg
              className="animate__animated animate__rubberBand"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.31 7.526c-.099-.807.528-1.526 1.348-1.526.771 0 1.377.676 1.28 1.451l-.757 6.053c-.035.283-.276.496-.561.496s-.526-.213-.562-.496l-.748-5.978zm1.31 10.724c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" />
            </svg>
            <h2>
              <Trans i18nKey="alert_no_database_connection_title" t={t}>
                Oh no, we are having trouble connecting to the database!
              </Trans>
            </h2>
            <div className="alert-text">
              <Trans i18nKey="alert_no_database_connection_text" t={t}>
                Sorry, but there seems to be an issue with our database connection or with your
                internet connection. If you made sure the issue is not on your side, please write us
                an E-Mail to <a href="mailto:myhexad@jeaninekrath.com">myhexad@jeaninekrath.com</a>{' '}
                and we will take a look at the connection problems right away. Sorry for the
                inconvenience and thank you for your help!
              </Trans>
            </div>
            <div className="alert-errorcode">
              <i>{isString(error) && error}</i>
            </div>
            <button className="back-link" onClick={closePopup}>
              {t('alert_no_database_connection_button', 'Close')}
            </button>
          </div>
        </div>
      )
    case 'RESULT_BUT_NOT_FINISHED':
      return (
        <div className="alert-modal" role="dialog">
          <div className="modal-content">
            <svg
              className="animate__animated animate__rubberBand"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.31 7.526c-.099-.807.528-1.526 1.348-1.526.771 0 1.377.676 1.28 1.451l-.757 6.053c-.035.283-.276.496-.561.496s-.526-.213-.562-.496l-.748-5.978zm1.31 10.724c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" />
            </svg>
            <h2>
              <Trans i18nKey="alert_missing_data_title" t={t}>
                {' '}
                Ups, i think you came here by mistake!
              </Trans>
            </h2>
            <div className="alert-text">
              <Trans i18nKey="alert_missing_data_text" t={t}>
                Please fill out our survey first so we can calculate your respective HEXAD type! If
                you already filled out the mandatory questions, you just encountered a Bug - we are
                sorry for that! In that case, please write us an email at{' '}
                <a href="mailto:myhexad@jeaninekrath.com">myhexad@jeaninekrath.com</a> so we can fix
                the problem right away. Thank you for your support!
              </Trans>
            </div>
            <Link className="back-link" to="/">
              {t('alert_consent_button', 'Zur Startseite')}
            </Link>
          </div>
        </div>
      )
    case 'NO_CONSENT':
      return (
        <div className="alert-modal" role="dialog">
          <div className="modal-content">
            <svg
              className="animate__animated animate__rubberBand"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.31 7.526c-.099-.807.528-1.526 1.348-1.526.771 0 1.377.676 1.28 1.451l-.757 6.053c-.035.283-.276.496-.561.496s-.526-.213-.562-.496l-.748-5.978zm1.31 10.724c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" />
            </svg>
            <h2>
              <Trans i18nKey="alert_consent_title" t={t}>
                Du musst der Datenschutzerklärung zustimmen!
              </Trans>
            </h2>
            <div className="alert-text">
              <Trans i18nKey="alert_consent_text" t={t}>
                Super, dass du direkt loslegen willst, allerdings musst du zunächst auf der{' '}
                <Link to="/">Startseite</Link> der Datenschutzbedingung zustimmen!
              </Trans>
            </div>
            <Link className="back-link" to="/">
              {t('alert_consent_button', 'Zur Startseite')}
            </Link>
          </div>
        </div>
      )
    default:
      break
  }
}
