import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import React from 'react'

import '../css/validation.css'

export default function Validation() {
  const pageCount = useSelector((state) => state.pageCount)
  const pagesWithMissingQuestions = useSelector((state) => state.pagesWithMissingQuestions)
  let { pageId } = useParams()
  const { t } = useTranslation()

  if (pageId.toString() !== pageCount.toString() || pagesWithMissingQuestions.length === 0) {
    return null
  }

  const ValidationResult = () =>
    pagesWithMissingQuestions.map((pageIndex, index) => {
      if (index === pagesWithMissingQuestions.length - 1) {
        return (
          <React.Fragment key={'validate-link-to-' + pageIndex}>
            {' '}
            <Link to={'/quiz/' + pageIndex}>{pageIndex}</Link>
          </React.Fragment>
        )
      } else if (index === pagesWithMissingQuestions.length - 2 && index > 0) {
        return (
          <React.Fragment key={'validate-link-to-' + pageIndex}>
            , <Link to={'/quiz/' + pageIndex}>{pageIndex}</Link> {t('general:and')}
          </React.Fragment>
        )
      } else if (index === pagesWithMissingQuestions.length - 2 && index === 0) {
        return (
          <React.Fragment key={'validate-link-to-' + pageIndex}>
            <Link to={'/quiz/' + pageIndex}>{pageIndex}</Link> {t('general:and')}
          </React.Fragment>
        )
      } else if (index === 0) {
        return (
          <React.Fragment key={'validate-link-to-' + pageIndex}>
            <Link to={'/quiz/' + pageIndex}>{pageIndex}</Link>
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment key={'validate-link-to-' + pageIndex}>
            , <Link to={'/quiz/' + pageIndex}>{pageIndex}</Link>
          </React.Fragment>
        )
      }
    })

  return (
    <div className="validation-warning">
      <div className="validation-warning-inner">
        <div className="validation-warning-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 18h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z" />
          </svg>
        </div>
        <div className="validation-warning-text">
          <Trans i18nKey="general:demographics_page_question_validation_failed" t={t}>
            Hey, it seems like there are still some unanswered questions on page
            <ValidationResult />. Without those answers, we cannot calculate your HEXAD type - so
            please revisit the pages and answer the questions!
          </Trans>
        </div>
      </div>
    </div>
  )
}
