import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import surveyData from '../data/survey-data'
import {
  updatedProgress,
  updatedPagesWithMissingQuestions,
  fetchSession,
  gotQuestionResponse,
  foundToken,
  fetchToken
} from './slice'
import { saveToken } from '../util/sessionStorage'
import { showAccordingToConditionalLogic } from '../util/conditionalLogicUtil'

export const listeners = createListenerMiddleware()
listeners.startListening({
  matcher: isAnyOf(fetchSession.fulfilled, gotQuestionResponse),
  effect: (action, { getState, dispatch }) => {
    updateProgress(getState(), dispatch)
    updateValidation(getState(), dispatch)
  }
})

listeners.startListening({
  matcher: isAnyOf(fetchToken.fulfilled),
  effect: (action, { getState, dispatch }) => {
    saveToken(getState().token)
  }
})

listeners.startListening({
  matcher: isAnyOf(foundToken),
  effect: (action, { getState, dispatch }) => {
    dispatch(fetchSession())
  }
})

const updateProgress = (state, dispatch) => {
  const progress = JSON.parse(JSON.stringify(state.progress))
  const responses = state.responses
  Object.values(progress).forEach((page, pageIndex) => {
    let count = 0
    page.elements.forEach(function (questionId) {
      if (responses.hasOwnProperty(questionId) && responses[questionId]) {
        count++
      }
    })

    // Check if there are any unanswered questions that might not be visible to the user
    let hiddenQuestions = 0
    surveyData.pages[pageIndex].elements.forEach((dataQuestion) => {
      if (!showAccordingToConditionalLogic(dataQuestion, responses)) hiddenQuestions++
    })

    page.answered = count
    const progress = (count / (page.elements.length - hiddenQuestions)) * 100
    page.progress = (progress < 0 ? 0 : progress > 100 ? 100 : progress).toFixed(1)
  })
  dispatch(updatedProgress(progress))
}

const updateValidation = (state, dispatch) => {
  const pages = []
  surveyData.pages.forEach((page, index) => {
    for (let question of page.elements) {
      if (
        question?.optional === false &&
        !(state.responses.hasOwnProperty(question.id) && state.responses[question.id]) &&
        showAccordingToConditionalLogic(question, state.responses)
      ) {
        pages.push(index + 1)
        break
      }
    }
  })
  dispatch(updatedPagesWithMissingQuestions(pages))
}
