import goOn from '../../assets/hexadguy/Goon.svg'

export default {
  id: 'sport_information_3',
  description: {
    en: 'Really good! Please continue with the second set of questions in the same way.',
    de: 'Toll! Mach bitte mit dem zweiten Fragensatz genauso weiter.'
  },
  image: goOn,
  layout: 'grid',
  elements: [
    {
      id: 'SMSA2',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To develop close friendships.',
        de: 'Enge Freundschaften aufzubauen, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMHM4',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To improve my endurance, stamina.',
        de: 'Meine Ausdauerfähigkeit zu erhöhen, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMSA4',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To share my exercise experiences with people that care for me.',
        de: 'Meine sportlichen Erfahrungen mit Menschen zu teilen, die mich mögen, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMSD4',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To learn and exercise new techniques.',
        de: 'Neue Techniken zu lernen und zu üben, ist mir...'
      },
      subtitle: {}
    },
    {
      id: 'SMSR2',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To be socially respected by others.',
        de: 'Von Anderen positive Anerkennung zu erfahren, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMI4',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To change my appearance by altering a specific area of my body.',
        de: 'Mein Aussehen zu verbessern, in dem ich bestimmte Bereiche meines Körpers verändere, ist mir ... '
      },
      subtitle: {}
    },
    {
      id: 'SMSD3',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To become skilled at a certain exercise or activity.',
        de: 'Bestimmte Übungen oder Aktivitäten kompetent ausführen zu können, ist mir ... '
      },
      subtitle: {}
    },
    {
      id: 'SMSR3',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To gain social recognition from others.',
        de: 'Bei Anderen sozial angesehen zu sein, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMSR4',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'So that others recognize me as an exerciser.',
        de: 'Dass Andere mich als Sporttreibenden erkennen, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMSD1',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To acquire new exercise skills.',
        de: 'Neue sportliche Fähigkeiten zu erwerben, ist mir ...'
      },
      subtitle: {}
    }
  ]
}
