import start from '../../assets/hexadguy/Start.svg'

export default {
  id: 'sport_information_2',
  description: {
    en: 'Very good! Please rate the importance of the following goals when training for your main sport on a scale from "not at all important" to "very important".',
    de: 'Sehr gut! Bitte bewerte die Priorität der folgenden Ziele beim Training deiner Hauptsportart auf einer Skala von "gar nicht wichtig" bis "sehr wichtig".'
  },
  image: start,
  layout: 'grid',
  elements: [
    {
      id: 'SMI1',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To improve the look of my overall body shape.',
        de: 'Das Erscheinungsbild meiner Körperform zu verbessern, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMSA1',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To form close bonds with others.',
        de: 'Enge Bindungen mit Anderen einzugehen, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMHM3',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To improve my overall health.',
        de: 'Meine Gesundheit insgesamt zu verbessern, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMSR1',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To be well thought of by others.',
        de: 'Von Anderen positiv beurteilt zu werden, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMHM1',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To increase my resistance to illness and disease.',
        de: 'Meine Widerstandsfähigkeit gegen gesundheitliche Beschwerden und Krankheit zu verbessern, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMI2',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To improve my appearance.',
        de: 'Mein Aussehen zu verbessern, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMSA3',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To connect with others in a meaningful manner.',
        de: 'Mit Anderen in einer für mich bedeutsam Art und Weise zusammen zu kommen, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMSD2',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To develop my exercise skills.',
        de: 'Meine sportlichen Fähigkeiten zu entwickeln, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMHM2',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To increase my energy level.',
        de: 'Mein Leistungsvermögen zu verbessern, ist mir ...'
      },
      subtitle: {}
    },
    {
      id: 'SMI3',
      type: {
        name: 'likert-7',
        options: {
          labelDisagree: {
            en: 'Not at all important',
            de: 'gar nicht wichtig'
          },
          labelAgree: {
            en: 'Extremely important',
            de: 'sehr wichtig'
          }
        }
      },
      optional: false,
      title: {
        en: 'To be slim so to look attractive to others.',
        de: 'Schlank zu sein, um attraktiver auszusehen, ist mir ...'
      },
      subtitle: {}
    }
  ]
}
