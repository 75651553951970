import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'

// Import any other slice reducers as usual here
import surveySessionReducer from './slice'
import { listeners } from './listener'

export const store = configureStore({
  reducer: surveySessionReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listeners.middleware)
})
