import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../css/privacy.css'
import privacy_de from '../lang/de/privacy.md'
import privacy_en from '../lang/en/privacy.md'
import Markdown from 'react-markdown'

export default function Privacy(props) {
  const { i18n } = useTranslation('imprint')
  const [markdown, setMarkdown] = useState('')

  useEffect(() => {
    fetch(i18n.language.substring(0, 2) === 'de' ? privacy_de : privacy_en)
      .then((res) => res.text())
      .then((text) => setMarkdown(text))
  }, [setMarkdown, i18n.language])

  return (
    <div className="privacy">
      <div className="content">
        <Markdown>{markdown}</Markdown>
      </div>
    </div>
  )
}
