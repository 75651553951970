import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'

import '../../css/questiontypes/radio-buttons.css'
import 'pretty-checkbox'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

export default function RadioButtons({
  handleChange: handleSurveyChange,
  question,
  options: definedOptions
}) {
  const { i18n } = useTranslation()
  const questionState = useSelector((state) => state.responses[question.id])

  const options = {
    horizontal: false,
    ...definedOptions
  }

  const handleChange = (event) => {
    let { name: questionId, value, id } = event.target
    handleSurveyChange(questionId, value)
  }

  const SubTitle = () => {
    if (question.hasOwnProperty('subtitle') && !isEmpty(question.subtitle)) {
      return (
        <div className="subtitle">
          <Markdown>{question.subtitle[i18n.language.substring(0, 2)]}</Markdown>
        </div>
      )
    }
  }

  const RadioButton = ({ questionId, selectedValue, value, label }) => {
    return (
      <div className="pretty p-default p-round p-smooth">
        <input
          type="radio"
          id={value}
          key={value}
          name={questionId}
          value={value}
          defaultChecked={selectedValue === value}
          onChange={handleChange}
        />
        <div className="state p-default">
          <label>{label[i18n.language.substring(0, 2)]}</label>
        </div>
      </div>
    )
  }

  return (
    <div className="radioButtonQuestion">
      <label htmlFor={question.id} className="title">
        <Markdown>{question.title[i18n.language.substring(0, 2)]}</Markdown>
      </label>
      <SubTitle />
      <div className={`radioButtons ${options.horizontal ? 'horizontal' : 'vertical'}`}>
        {question.choices.map((choice, i) => (
          <RadioButton
            questionId={question.id}
            selectedValue={questionState}
            value={choice.value}
            label={choice.label}
            key={choice.value}
          />
        ))}
      </div>
    </div>
  )
}
