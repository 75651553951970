import pagination_en from './en/pagination.json'
import home_en from './en/home.json'
import general_en from './en/general.json'
import result_en from './en/result.json'
import error_en from './en/error.json'

import pagination_de from './de/pagination.json'
import home_de from './de/home.json'
import general_de from './de/general.json'
import result_de from './de/result.json'
import error_de from './de/error.json'

export const resources = {
  en: {
    pagination: pagination_en,
    home: home_en,
    general: general_en,
    result: result_en,
    error: error_en
  },
  de: {
    pagination: pagination_de,
    home: home_de,
    general: general_de,
    result: result_de,
    error: error_de
  }
}
