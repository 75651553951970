import React from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'

import '../../css/questiontypes/number-input.css'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

export default function NumberInput({ handleChange: handleSurveyChange, question, options }) {
  const { i18n } = useTranslation()
  const questionState = useSelector((state) => state.responses[question.id])

  const handleChange = (event) => {
    let questionId = question.id
    let value = event.target.value
    if (!/\D/.test(value)) {
      handleSurveyChange(questionId, value)
    }
  }

  const handleBlur = (event) => {
    let { name: questionId, value } = event.target
    handleSurveyChange(questionId, value)
  }

  const SubTitle = () => {
    if (question.hasOwnProperty('subtitle') && !isEmpty(question.subtitle)) {
      return (
        <div className="subtitle">
          <Markdown>{question.subtitle[i18n.language.substring(0, 2)]}</Markdown>
        </div>
      )
    }
  }

  return (
    <div className="numberInputQuestion">
      <label htmlFor={question.id} className="title">
        <Markdown>{question.title[i18n.language.substring(0, 2)]}</Markdown>
      </label>
      <SubTitle />
      <input
        type="text"
        inputMode="numeric"
        pattern="[0-9]"
        name={question.id}
        id={question.id}
        key={question.id}
        value={questionState || ''}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </div>
  )
}
