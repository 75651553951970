import React from 'react'
import { useTranslation } from 'react-i18next'
import Creatable from 'react-select/creatable'
import Selectable from 'react-select'
import Markdown from 'react-markdown'

import '../../css/questiontypes/dropdown.css'
import countriesDE from '../../data/countries/de.countries.json'
import countriesEN from '../../data/countries/en.countries.json'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

export default function Dropdown({ handleChange: handleSurveyChange, question, options }) {
  const { i18n, t } = useTranslation()
  const questionState = useSelector((state) => state.responses[question.id])

  const handleChange = (event) => {
    let questionId = question.id
    let value = event.value
    handleSurveyChange(questionId, value)
  }

  const constructValue = (questionState) => {
    if (!questionState) return { value: '', label: '' }

    const localizedOptions = getLocalizedOptions()
    let localizedOption

    for (let option of localizedOptions) {
      if (option.value === questionState) {
        localizedOption = option
        break
      }
    }

    if (localizedOption && localizedOption.hasOwnProperty('value')) {
      return localizedOption
    } else {
      return { value: questionState, label: questionState }
    }
  }

  const getLocalizedOptions = () => {
    let localizedOptions = []
    if (options && options.hasOwnProperty('preset')) {
      return getPresetOptions()
    }
    if (!question.hasOwnProperty('choices')) return localizedOptions
    for (let choice of question.choices) {
      localizedOptions.push({
        value: choice.value,
        label: choice.label[i18n.language.substring(0, 2)]
      })
    }
    return localizedOptions
  }

  const getPresetOptions = () => {
    if (options.preset) {
      switch (options.preset) {
        case 'countries':
          return getCountries()
      }
    }
  }

  const getCountries = () => {
    let countries = []
    let raw = i18n.language.substring(0, 2) === 'de' ? countriesDE : countriesEN

    raw.forEach((value) => {
      countries.push({ value: value.alpha2, label: value.name })
    })

    return countries
  }

  const SelectWrapper = (props) => {
    if (options?.creatable) {
      return <Creatable {...props} />
    } else {
      return <Selectable {...props} />
    }
  }

  const SubTitle = () => {
    if (question.hasOwnProperty('subtitle') && !isEmpty(question.subtitle)) {
      return (
        <div className="subtitle">
          <Markdown>{question.subtitle[i18n.language.substring(0, 2)]}</Markdown>
        </div>
      )
    }
  }

  return (
    <div className="dropdownQuestion">
      <label htmlFor={question.id} className="title">
        <Markdown>{question.title[i18n.language.substring(0, 2)]}</Markdown>
      </label>
      <SubTitle />
      <SelectWrapper
        name={question.id}
        id={question.id}
        key={question.id}
        value={constructValue(questionState)}
        placeholder=""
        options={getLocalizedOptions()}
        onChange={handleChange}
        noOptionsMessage={() => <>{t('general:question_dropdown_no_option')}</>}
        formatCreateLabel={(inputValue) => (
          <>
            {t('general:question_dropdown_create_new')} {inputValue}
          </>
        )}
      />
    </div>
  )
}
