import nice from '../../assets/hexadguy/Nice.svg'

export default {
  id: 'sport_information',
  description: {
    en: 'The next part is about which sport you have mainly been doing and focusing on **in the last month.**',
    de: 'Im nächsten Teil geht es darum, welche Sportart du **im letzten Monat** hauptsächlich ausgeübt hast und was dein aktueller Schwerpunkt ist.'
  },
  image: nice,
  className: 'no-wrap',
  layout: 'single-column',
  elements: [
    {
      id: 'SCS1',
      type: { name: 'drop-down', options: { creatable: true } },
      optional: false,
      title: {
        en: `If you are currently engaged in sports, please specify the main sport you are currently focussing on or working on.`,
        de: `Falls du derzeit Sport treibst, gib bitte die Hauptsportart an, auf die du dich derzeit konzentrierst oder an der du arbeitest.`
      },
      subtitle: {
        en: `If your sport is not listed, you can write it directly in the drop-down menu and add it.
             If you engage in a variety of activities and are not specifically focused on one sport, you can select "Fitness" if applicable.  
             If sport has not been a significant part of your life in the last month, you can simply select "None".`,
        de: `Wenn deine Sportart nicht aufgeführt ist, kannst du sie direkt in das Drop-Down-Menu schreiben und hinzufügen.  
             Wenn du verschiedene Aktivitäten ausübst und dich nicht speziell auf eine Sportart konzentrierst, kannst du "Fitness" auswählen, falls zutreffend.  
             Wenn Sport im letzten Monat keine große Rolle in deinem Leben gespielt hat, kannst du einfach "Keinen" auswählen.`
      },
      choices: [
        { value: 'none', label: { en: 'None', de: 'Keinen' } },
        { value: 'athletics', label: { en: 'Athletics', de: 'Leichtathletik' } },
        { value: 'badminton', label: { en: 'Badminton', de: 'Badminton' } },
        { value: 'baseball', label: { en: 'Baseball', de: 'Baseball' } },
        { value: 'basketball', label: { en: 'Basketball', de: 'Basketball' } },
        { value: 'bowling', label: { en: 'Bowling', de: 'Bowling' } },
        { value: 'climbing', label: { en: 'Climbing/Bouldering', de: 'Klettern/Bouldern' } },
        { value: 'cricket', label: { en: 'Cricket', de: 'Cricket' } },
        { value: 'cycling', label: { en: 'Cycling', de: 'Radfahren' } },
        { value: 'dancing', label: { en: 'Dancing', de: 'Tanzen' } },
        { value: 'fieldhockey', label: { en: 'Field hockey', de: 'Feldhockey' } },
        { value: 'fishing', label: { en: 'Fishing', de: 'Angeln' } },
        { value: 'fitness', label: { en: 'Fitness', de: 'Fitness' } },
        { value: 'soccer', label: { en: 'Football (Soccer)', de: 'Fußball' } },
        { value: 'golfing', label: { en: 'Golfing', de: 'Golf' } },
        { value: 'gymnastics', label: { en: 'Gymnastics', de: 'Turnen' } },
        { value: 'handball', label: { en: 'Handball', de: 'Handball' } },
        { value: 'hiking', label: { en: 'Hiking', de: 'Wandern' } },
        { value: 'horseriding', label: { en: 'Horse riding', de: 'Reiten' } },
        { value: 'martialarts', label: { en: 'Martial arts', de: 'Kampfsport' } },
        { value: 'netball', label: { en: 'Netball', de: 'Netball' } },
        { value: 'resistancetraining', label: { en: 'Resistance training', de: 'Krafttraining' } },
        { value: 'rugby', label: { en: 'Rugby', de: 'Rugby' } },
        { value: 'running', label: { en: 'Running', de: 'Laufen/Joggen' } },
        { value: 'skating', label: { en: 'Skating', de: 'Skaten' } },
        { value: 'skiing', label: { en: 'Skiing/Snowboarding', de: 'Skifahren/Snowboarden' } },
        { value: 'squash', label: { en: 'Squash', de: 'Squash' } },
        { value: 'swimming', label: { en: 'Swimming', de: 'Schwimmen' } },
        { value: 'tabletennis', label: { en: 'Table tennis', de: 'Tischtennis' } },
        { value: 'tennis', label: { en: 'Tennis', de: 'Tennis' } },
        { value: 'volleyball', label: { en: 'Volleyball', de: 'Volleyball' } },
        { value: 'walking', label: { en: 'Walking', de: 'Walking/Gehen' } },
        { value: 'yoga', label: { en: 'Yoga', de: 'Yoga' } }
      ]
    },
    {
      id: 'SCS2',
      type: { name: 'radio-buttons', options: {} },
      optional: false,
      title: {
        en: 'How frequent do you train this sport?',
        de: 'Wie häufig trainierst du diesen Sport?'
      },
      visibleIf: "SCS1 !== 'none' && SCS1 !== null",
      hiddenIf: '',
      subtitle: {},
      choices: [
        {
          value: 'once-month',
          label: { en: 'Less than or once a month', de: 'Weniger als oder einmal im Monat' }
        },
        {
          value: 'multiple-times-month',
          label: { en: '2-3 times a month', de: '2-3 Mal im Monat' }
        },
        { value: 'once-week', label: { en: 'once a week', de: 'einmal in der Woche' } },
        {
          value: 'multiple-times-week',
          label: { en: '2-5 times a week', de: '2-5 Mal in der Woche' }
        },
        { value: 'daily', label: { en: 'every day', de: 'jeden Tag' } }
      ]
    },
    {
      id: 'SCS3',
      type: { name: 'radio-buttons', options: { horizontal: true } },
      optional: false,
      title: {
        en: 'Have you participated in any competitions in this sport within the past half year?',
        de: 'Hast du im letzten halben Jahr an Wettkämpfen in dieser Sportart teilgenommen?'
      },
      visibleIf: "SCS1 !== 'none' && SCS1 !== null",
      hiddenIf: '',
      subtitle: {},
      choices: [
        {
          value: 'yes',
          label: { en: 'Yes', de: 'Ja' }
        },
        {
          value: 'no',
          label: { en: 'No', de: 'Nein' }
        }
      ]
    }
  ]
}
