import ready from '../../assets/hexadguy/Ready.svg'

export default {
  id: 'big_five_traits_2',
  description: {
    en: 'Great! Please continue with the second set of questions in the same way.',
    de: 'Toll! Mach bitte mit dem zweiten Fragensatz genauso weiter.'
  },
  image: ready,
  layout: 'grid',
  elements: [
    {
      id: 'BFC1R',
      type: { name: 'likert-7', options: {} },
      optional: false,
      title: {
        en: '... tends to be lazy.',
        de: '... bin bequem, neige zur Faulheit.'
      },
      subtitle: {}
    },
    {
      id: 'BFN3',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... is depressed, blue.',
        de: '... werde leicht deprimiert, niedergeschlagen.'
      },
      subtitle: {}
    },
    {
      id: 'BFO3R',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... has few artistic interests.',
        de: '... habe nur wenig künstlerisches Interesse.'
      },
      subtitle: {}
    },
    {
      id: 'BFE4R',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... tends to be quiet.',
        de: '... bin eher der „stille Typ“, wortkarg.'
      },
      subtitle: {}
    },
    {
      id: 'BFO4',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... is ingenious, a deep thinker.',
        de: '... bin tiefsinnig, denke gerne über Sachen nach.'
      },
      subtitle: {}
    },
    {
      id: 'BFC2',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... makes plans and follows through with them.',
        de: '... mache Pläne und führe sie auch durch.'
      },
      subtitle: {}
    },
    {
      id: 'BFO5',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... has an active imagination.',
        de: '... habe eine aktive Vorstellungskraft, bin phantasievoll.'
      },
      subtitle: {}
    },
    {
      id: 'BFA4R',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... is sometimes rude to others.',
        de: '... kann mich schroff und abweisend anderen gegenüber verhalten.'
      },
      subtitle: {}
    },
    {
      id: 'BFC3R',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... does things efficiently.',
        de: '... bin tüchtig und arbeite flott.'
      },
      subtitle: {}
    },
    {
      id: 'BFN4',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... worries a lot.',
        de: '... mache mir viele Sorgen.'
      },
      subtitle: {}
    },
    {
      id: 'BFC4',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... does a thorough job.',
        de: '... erledige Aufgaben gründlich.'
      },
      subtitle: {}
    }
  ]
}
