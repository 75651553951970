import hello from '../../assets/hexadguy/Hello.svg'

export default {
  id: 'big_five_traits',
  description: {
    en: `Really cool. This part contains a number of **characteristics** that may or may not apply to you. Similarly, to the previous section, please indicate the extent to which you agree or disagree with each statement.  
      **I see myself as someone who …**`,
    de: `Wirklich cool. Dieser Teil enthält eine Reihe von **Eigenschaften**, die auf dich zutreffen können oder auch nicht. Ähnlich wie im vorherigen Abschnitt kannst du auch hier angeben, inwieweit du diesen Aussagen zustimmst oder nicht.   
      **Ich …**`
  },
  image: hello,
  layout: 'grid',
  elements: [
    {
      id: 'BFE1',
      type: { name: 'likert-7', options: {} },
      optional: false,
      title: {
        en: '... is outgoing, sociable.',
        de: '... gehe aus mir heraus, bin gesellig.'
      },
      subtitle: {}
    },
    {
      id: 'BFN1R',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... is relaxed, handles stress well.',
        de: '... bin entspannt, lasse mich durch Stress nicht aus der Ruhe bringen.'
      },
      subtitle: {}
    },
    {
      id: 'BFE2',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... generates a lot of enthusiasm.',
        de: '... bin begeisterungsfähig und kann andere leicht mitreißen.'
      },
      subtitle: {}
    },
    {
      id: 'BFA1R',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... can be cold and aloof.',
        de: '... kann mich kalt und distanziert verhalten.'
      },
      subtitle: {}
    },
    {
      id: 'BFE3R',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... is reserved.',
        de: '... bin eher zurückhaltend, reserviert.'
      },
      subtitle: {}
    },
    {
      id: 'BFO1',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... is curious about many different things.',
        de: '... bin vielseitig interessiert.'
      },
      subtitle: {}
    },
    {
      id: 'BFA2',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... is generally trusting.',
        de: '... schenke anderen leicht Vertrauen, glaube an das Gute im Menschen.'
      },
      subtitle: {}
    },
    {
      id: 'BFA3',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... tends to find fault with others.',
        de: '... neige dazu, andere zu kritisieren.'
      },
      subtitle: {}
    },
    {
      id: 'BFO2',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... values artistic, aesthetic experiences.',
        de: '... schätze künstlerische und ästhetische Eindrücke.'
      },
      subtitle: {}
    },
    {
      id: 'BFN2',
      type: {
        name: 'likert-7',
        options: {}
      },
      optional: false,
      title: {
        en: '... gets nervous easily.',
        de: '... werde leicht nervös und unsicher.'
      },
      subtitle: {}
    }
  ]
}
