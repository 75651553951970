import React, { memo, useCallback } from 'react'
import Likert7 from './questiontypes/Likert7'
import Dropdown from './questiontypes/Dropdown'
import RadioButtons from './questiontypes/RadioButtons'
import '../css/questiontypes/radio-buttons.css'
import 'pretty-checkbox'
import NumberInput from './questiontypes/NumberInput'
import { useDispatch, useSelector } from 'react-redux'
import { gotQuestionResponse } from '../redux/slice'
import { useParams } from 'react-router-dom'
import { showAccordingToConditionalLogic } from '../util/conditionalLogicUtil'

export default ({ data }) => {
  const { pageId } = useParams()
  const responses = useSelector((state) => state.responses)
  const dispatch = useDispatch()

  const handleChange = (id, value) => {
    dispatch(gotQuestionResponse({ id, value }))
  }

  return (
    <div
      className={data.pages[pageId - 1].className ? data.pages[pageId - 1].className : undefined}
    >
      <div className="question-list">
        {data.pages[pageId - 1].elements.map((question, index) => {
          return showAccordingToConditionalLogic(question, responses) ? (
            <MomorizedQuestion
              question={question}
              key={question.id}
              handleChange={handleChange}
              options={question.type.options}
            />
          ) : null
        })}
      </div>
    </div>
  )
}

const MomorizedQuestion = memo(({ question, handleChange, options }) => {
  switch (question.type.name) {
    case 'likert-7':
      return (
        <Likert7
          question={question}
          key={question.id}
          handleChange={handleChange}
          options={options}
        />
      )
    case 'drop-down':
      return (
        <Dropdown
          question={question}
          key={question.id}
          handleChange={handleChange}
          options={options}
        />
      )
    case 'radio-buttons':
      return (
        <RadioButtons
          question={question}
          key={question.id}
          handleChange={handleChange}
          options={options}
        />
      )
    case 'number-input':
      return (
        <NumberInput
          question={question}
          key={question.id}
          handleChange={handleChange}
          options={options}
        />
      )
  }
})
