import hello from '../assets/hexadguy/Hello.svg'
import ready from '../assets/hexadguy/Ready.svg'
import achiever from '../assets/hexadguy/Achiever.svg'
import disruptor from '../assets/hexadguy/Disruptor.svg'
import freeSpirit from '../assets/hexadguy/FreeSpirit.svg'
import philanthropist from '../assets/hexadguy/Philantropist.svg'
import player from '../assets/hexadguy/Player.svg'
import socializer from '../assets/hexadguy/Socializer.svg'
import errorguy from '../assets/hexadguy/errorguy.svg'
import { p1, p2, p3, p4, p5, p6, p7 } from './pages'

export {
  hello,
  achiever,
  disruptor,
  freeSpirit,
  philanthropist,
  player,
  socializer,
  errorguy,
  ready
}

const surveyData = {
  pages: [p1, p2, p3, p4, p5, p6, p7]
}

export default surveyData
