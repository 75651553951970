const { evaluate } = require('angel-eval')

export const showAccordingToConditionalLogic = (question, responses) => {
  let visibleIfResult = null
  let hiddenIfResult = null
  if (question?.visibleIf) {
    visibleIfResult = evaluate(question.visibleIf, responses)
  }
  if (question?.hiddenIf) {
    hiddenIfResult = evaluate(question.hiddenIf, responses)
  }
  if (visibleIfResult === null && hiddenIfResult === null) return true
  if ('boolean' == typeof visibleIfResult && hiddenIfResult === null) return visibleIfResult
  if (visibleIfResult === null && 'boolean' == typeof hiddenIfResult) return !hiddenIfResult
  if (visibleIfResult === !hiddenIfResult) return visibleIfResult
  // If we find contradicting conditional logic, show the question
  return true
}
