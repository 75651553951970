import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import '../css/about-hexad.css';
import abouthexad from '../assets/hexadguy/about-hexad.svg';
import { freeSpirit, philanthropist, socializer, achiever, player, disruptor } from '../data/survey-data';

export default function AboutHexad(props){
    const { t } = useTranslation('result');
      
    return (
        <div className="about-hexad">
            <div className="header-image-container">
                <div className="header-image">
                    <img src={abouthexad} alt=""></img>
                </div>
            </div>
            <div className ="content">
                <div className="title">
                    {t("heading", "About the HEXAD gamification user types")}
                </div>
                <div className="text">
                    <Trans i18nKey="about-text" t={t}>
                        Test
                    </Trans>
                </div>
                <div id="achiever" className="type-block">
                    <div className="type-image-container">
                            <img src={achiever} alt=""></img>
                    </div>
                    <div className="type-description"> 
                        <span className="text-bold">{t("achiever.name")}</span>
                        <br /><br />
                        <Trans i18nKey={"achiever.longDescription"} t={t}></Trans>
                    </div>  
                </div> 
                <div id="disruptor" className="type-block" >
                    <div className="type-description"> 
                        <span className="text-bold">{t("disruptor.name")}</span>
                        <br /><br />
                        <Trans i18nKey={"disruptor.longDescription"} t={t}></Trans>
                    </div> 
                    <div className="type-image-container">
                            <img src={disruptor} alt=""></img>
                    </div> 
                </div>
                <div  id="freeSpirit" className="type-block">
                    <div className="type-description"> 
                        <span className="text-bold">{t("freeSpirit.name")}</span>
                        <br /><br />
                        <Trans i18nKey={"freeSpirit.longDescription"} t={t}></Trans>
                    </div> 
                    <div className="type-image-container">
                            <img src={freeSpirit} alt=""></img>
                    </div> 
                </div>
                <div  id="philanthropist" className="type-block">
                    <div className="type-description"> 
                        <span className="text-bold">{t("philanthropist.name")}</span>
                        <br /><br />
                        <Trans i18nKey={"philanthropist.longDescription"} t={t}></Trans>
                    </div> 
                    <div className="type-image-container">
                            <img src={philanthropist} alt=""></img>
                    </div> 
                </div>
                <div id="player" className="type-block">
                    <div className="type-description"> 
                        <span className="text-bold">{t("player.name")}</span>
                        <br /><br />
                        <Trans i18nKey={"player.longDescription"} t={t}></Trans>
                    </div> 
                    <div className="type-image-container">
                            <img src={player} alt=""></img>
                    </div> 
                </div>
                <div id="socializer" className="type-block">
                    <div className="type-description"> 
                        <span className="text-bold">{t("socializer.name")}</span>
                        <br /><br />
                        <Trans i18nKey={"socializer.longDescription"} t={t}></Trans>
                    </div> 
                    <div className="type-image-container">
                            <img src={socializer} alt=""></img>
                    </div> 
                </div> 
            </div>
        </div>    
    );
}