import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import '../css/survey-description.css'
import Markdown from 'react-markdown'

export default function SurveyDescription({ data }) {
  let { pageId } = useParams()
  const { i18n } = useTranslation('page_descriptions')

  const PageDescription = () => {
    if (pageId - 1 < data.pages.length && data.pages[pageId - 1].description !== null) {
      return (
        <div id="pageDescription" className="pageDescription">
          <div className="description">
            <div className="descriptionText">
              <Markdown>
                {data.pages[pageId - 1].description[i18n.language.substring(0, 2)]}
              </Markdown>
            </div>
            <div className="descriptionImage">
              <img className="iconImage" alt="" src={data.pages[pageId - 1].image}></img>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
  return <PageDescription />
}
