import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import './i18n'
import App from './container/App'
import { store } from './redux/store'

const Render = () => {
  return (
    <Provider store={store}>
      <StrictMode>
        <Router>
          <App />
        </Router>
      </StrictMode>
    </Provider>
  )
}
const root = createRoot(document.getElementById('root'))

root.render(<Render />)
