import React from 'react';
import { errorguy } from '../data/survey-data';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../css/notfound.css'

export default function NotFound(props){
    const { t } = useTranslation('error');

    return (
        <div className="notfound">
            <div className ="content">
                <div className="right">
                    <div className="titleguy">
                        <img className="titleguy-image" src={errorguy} alt=""></img>
                    </div>
                </div>
                <div className="left">
                    <div className="title">
                    {t("error_heading", "Oops, this page does not exist...")}
                    </div>
                    <div className="text">
                        <Trans i18nKey="error_text" t={t}>
                        Oh, you are not supposed to land here! Unfortunately, this is not the way forward.<br /><br />
                        Click on the back button of your browser to return to the page you came from. If this doesn't work, click on the button below to return to the start page and try again.
                        </Trans>
                    </div>
                    <div className="startbutton">
                        <Link to="/" className="startbutton-inner">
                        {t("error_button", "Back to the start page")}
                        </Link>
                    </div>
                </div>    
            </div>
        </div>
    );
}