import React from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import '../css/pagination.css'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { syncSession } from '../redux/slice'

export default function Pagination({}) {
  let { pageId } = useParams()
  const navigate = useNavigate()
  const pageCount = useSelector((state) => state.pageCount)
  const pagesWithMissingQuestions = useSelector((state) => state.pagesWithMissingQuestions)
  const dispatch = useDispatch()

  const { t } = useTranslation('pagination')

  const PreviousButton = () => {
    if (pageId > 1) {
      return <Link to={'/quiz/' + (parseFloat(pageId) - 1)}>{t('previousPage')}</Link>
    } else if (parseInt(pageId) === 1) {
      return <Link to="/">{t('previousPage')}</Link>
    }
  }

  const NextButton = () => {
    if (pageId < pageCount) {
      return (
        <Link to={'/quiz/' + (parseFloat(pageId) + 1)} onClick={() => dispatch(syncSession())}>
          {t('nextPage')}
        </Link>
      )
    }
  }

  const FinalizeButton = () => {
    const handleSubmit = async (event) => {
      event.preventDefault()
      if (pagesWithMissingQuestions.length === 0) {
        await dispatch(syncSession())
        navigate('/result')
      }
    }

    if (pageId.toString() === pageCount.toString()) {
      return (
        <button
          type="button"
          className={`${pagesWithMissingQuestions.length > 0 ? 'disabled' : ''}`}
          onClick={handleSubmit}
        >
          {t('submit')}
        </button>
      )
    }
  }

  return (
    <div className="pagination">
      <PreviousButton />
      <NextButton />
      <FinalizeButton />
    </div>
  )
}
